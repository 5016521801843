.top-footer {
	background: #ccc;
	width:100%;
	height:250px;
	margin-top: 75px;
		
	.custom-quote-outter {
		box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
		margin-top: 50px;
    width: 100%;
    background: $brand-primary;
    float: left;
		color:$white;
		
		.custom-quote-inner-right{
			
			.quote-button {
				margin-top: 20px;
				background: #000;
				
				.quote-button-link {
					padding: 15px 5px;
					font-size:1.3em;
					text-align: center;
					display: inline-block;	
					width:100%;			
				}
			}
		}
	}
}
footer { 
	font-family: 'Open Sans';
			
	#menu-footer-menu {
		padding:0;
	}
	.widget {
				
		h3 {
			font-size: 19px;
			margin-bottom: 10px !important;
		}
	}
	.footer-menu {
		text-align:left !important;
	}
	.seal {
		text-align:center !important;
	}
	.contact-info {
		text-align:right !important;
	}
	b {
		color:#f47421;
	}

	.bottom-widgets-wrapper {
		background-color:$gray-darker;
		color:$white;
		padding:1.5em 0;
		font-size: 16px;
			h3 {
				font-weight: 700;
				letter-spacing: 1px;
				text-transform: uppercase;
				margin:0 0 20px 0;
			}
			li {
				list-style: none;
				margin: 0px;
				padding:0;
				
				a {
					color:$white;
				
					&:visited {
						color: $white;
					}
					&:hover {
						color: $brand-primary;
					}
					&:active {
						color: $gray-darker;
					}
				}
			}
	}
	.page-bottom-wrapper {
		background-color:$gray-darker;
		color:$white;
		padding:1em 0;
		font-size: 14px;
		border-top: solid 1px $white;
	}
}