// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


/*********************************************/
/*****    GRAVITY FORMS RESET STYLES     *****/
/*********************************************/
#gform_submit_button_3 {
	background-color:$gray-dark !important;
	width: 97.5% !important;
}
.gform_wrapper ul li.gfield {
    margin-top: 10px !important;
}
li.gfield {
    padding: 0 15px 5px !important;
}
.gform_wrapper {
	max-width:100% !important;
}
.ginput_container input, .ginput_container textarea {
	margin:0 !important;
}
.gform_wrapper .gform_footer {
	text-align:center;
	padding: 0 !important;
	margin:0 !important; 
}
.gform_button {
    background: #ff7302 !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
    color: #fff !important;
    font-weight: 700;
    text-align: center;
    border: none;
    width: 91% !important;
    padding: 12px 30px !important;
    text-transform: uppercase;
    margin: 0px auto !important;
    display: block;
}

.gform_wrapper .hidden_label input.large, .gform_wrapper .hidden_label select.large, .gform_wrapper .top_label input.large, .gform_wrapper .top_label select.large, .gform_wrapper textarea.small {
    width: 100% !important;
    background: #fff !important;
    border: solid 1px #a4a4a4 !important;
    color: #a4a4a4;
    display: block !important;
		padding: 10px!important;
    border-bottom: 1px solid #fff;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #555;
}

// These styles are made in mind to be used with SCSS or LESS
// If you use these styles, you must go to the Gravity Forms settings in the WordPress back end and set Output CSS to No.

////----- Core Containers
.gform_heading,
.gform_body,
.gform_footer {
	clear: both;
	padding-bottom: 20px;
}

////----- Fields Container
ul.gform_fields {
	list-style: none;
	margin: 0 -15px;
	padding: 0;
}

////----- Field Containers and their Contents
li.gfield {
	clear: both;
	overflow: auto;
	padding: 0 15px 20px;
}
.ginput_container {
	span {
		margin-bottom: 10px;
	}
	input,
	select,
	textarea {
		&.large {
			width: 100%;
		}
		&.medium {
			width: 66%;
		}
		&.small {
			width: 33%;
		}
	}

	input, 
	textarea {
		margin: 2px;
	}
}
label.gfield_label {
	display: inline-block;
	padding-bottom: 10px;
	.left_label & {
		float: left;
		width: 25%;
	}
	.right_label & {
		float: right;
		width: 25%;
	}
}

.gfield_required {
	padding-left: 5px;
}

////----- Checkboxes and Radios
ul.gfield_checkbox,
ul.gfield_radio {
	list-style: none;
	padding: 0;
	input {
		margin-right: 10px;
		position: relative;
		top: -1px;
	}
}
ul.gfield_radio {
	input {
		margin-left: 2px;
	}
}

////----- Section Break
.gsection {
	h2 {
		border-bottom: 3px solid black;
		padding-bottom: 15px;
	}
}

////----- Page Break
.gf_progressbar_wrapper {
	padding-bottom: 15px;
}
.gf_progressbar {
	background: gray;
}
.gf_progressbar_percentage {
	background: red;
	padding: 5px 10px;
	text-align: right;
}

////----- Time Field
.gfield_time_hour, .gfield_time_minute, .gfield_time_ampm {
	display: inline;
	display: inline-block;
}
.gfield_time_hour, .gfield_time_minute {
	label {
		display: block;
	}
}
.gfield_time_ampm {
	vertical-align: top;
}

////----- Validation and Errors
.validation_error {
	font-size: 1.2em;
	font-weight: bold;
	padding-bottom: 15px;
}

li.gfield {
	&.gfield_error {
		background: #FFDFE0;
		margin-bottom: 15px;
		padding-top: 15px;
		label.gfield_label {
			font-weight: bold;
		}
	}
	&.gfield_contains_required {

	}
	.validation_message {
		font-weight: bold;
		margin-top: 10px;
	}
}

.gform_validation_container {
	display: none;
}

////----- CSS Ready Classes
.ginput_full {
	display: block;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}
.ginput_left {
	float: left;
	padding-right: 10px;
	width: 50%;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}
.ginput_right {
	float: right;
	width: 50%;
	input, select, textarea {
		margin-bottom: 5px;
		width: 100%;
	}
	label {
		display: block;
	}
}
.ginput_right {
	float: right;
	width: 50%;
}

//----- Layout Columns
li.gf_left_half {
	clear: none !important;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 50%;
}
li.gf_right_half {
	clear: none !important;
	float: right;
	padding-bottom: 10px;
	width: 50%;
}
li.gf_left_third {
	clear: none !important;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 33%;
}
li.gf_middle_third {
	clear: none !important;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
}
li.gf_right_third {
	clear: none !important;
	float: right;
	padding-bottom: 10px;
	width: 33%;
}
li.gf_first_quarter {
	clear: none !important;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 25%;
}
li.gf_second_quarter {
	clear: none !important;
	float: left;
	padding-right: 10px;
	padding-bottom: 10px;
	width: 25%;
}
li.gf_third_quarter {
	clear: none !important;
	float: left;
	padding-bottom: 10px;
	width: 25%;
}
li.gf_fourth_quarter {
	clear: none !important;
	float: right;
	padding-bottom: 10px;
	width: 25%;
}

@media screen and (max-width:480px){
	li {
		&.gf_left_half,
		&.gf_right_half,
		&.gf_left_third,
		&.gf_middle_third,
		&.gf_right_third,
		&.gf_first_quarter,
		&.gf_second_quarter,
		&.gf_third_quarter,
		&.gf_fourth_quarter {
			width: 100%;
		}
	}
}

//----- List Columns
.gf_list_2col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 50%;
		}
	}
}
.gf_list_3col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 33%;
		}
	}
}
.gf_list_4col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 25%;
		}
	}
}
.gf_list_5col {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			float: left;
			padding-bottom: 10px;
			width: 20%;
		}
	}
}
.gf_list_inline {
	ul.gfield_checkbox,
	ul.gfield_radio {
		li {
			display: inline-block;
			padding-right: 10px;
			padding-bottom: 10px;
		}
	}
}

@media screen and (max-width:480px){
	.gf_list_2col,
	.gf_list_3col,
	.gf_list_4col,
	.gf_list_5col {
		ul.gfield_checkbox,
		ul.gfield_radio {
			li {
				width: 100%;
			}
		}
	}
}