header.banner {
	margin: 0;
	
	.navbar-brand {
    height: auto;
		padding: 15px 0;
	}
	
	.navbar-header-wrapper,
	.top-nav-wrapper  {
		padding:0;
	}
	
	.navbar-header-wrapper {
		
		.last-menu-item a{
			padding-right:0 !important;
		}
	}
	
	.top-nav-wrapper {
		background-color:$gray-dark;
		color:$gray-medium;
		font-size:14px;
		
		a {
			color:$gray-medium;
			transition: color 0.2s linear;
			text-decoration:none;
		
			&:hover {
				color: $link-hover-color;
				text-decoration:none;
			}
			
			&:visited {
				color: $link-visited-color;
			}
			
			&:active {
				color: $link-active-color;
			}
		}
		
		.top-right {
			text-align: right;
		}
		.top-menu,
		.top-sep {
			display:inline-block;
		}

		.top-sep {
			padding:5px 10px;
		}
		.top-menu {
			
			ul#menu-top-menu {	
				margin:0;				
				float: none;
				
				li {
					float: none;
					line-height: auto;
					font-size: 14px;
					text-align:right;
					position: relative;
					list-style: none outside none;
					margin: 0;
					z-index: 10;
					
					a {
						padding:5px 0;
						float: none;
					}
				}
			}
		}
		
		}
	
		.phone-number {
			margin:0;
			padding: 5px 0 5px 0px;
			text-align:right;
			display:inline-block;
		}
		.social-nav {
			margin:0;
			padding: 5px 0 5px 0px;
				
		ul#social {
			float: left;
			margin:0;
			padding:0;
			
				li {
				float: left;
				list-style:none;
				
				a {
					padding: 5px 0 5px 10px;
					height: 16px;
					position: relative;
					transition: all 0.2s linear;
					-moz-transition: all 0.2s linear;
					-webkit-transition: all 0.2s linear;
					background-position: center top;
					
					i {
						color:$gray-medium;
						transition: color 0.2s linear;
						text-decoration:none;
					
						&:hover {
							color: $link-hover-color;
							text-decoration:none;
						}
						
						&:visited {
							color: $link-visited-color;
						}
						
						&:active {
							color: $link-active-color;
						}
					}
				}
			}
		}		
	}
	nav {
		
		.navbar-brand {
			margin: 0;
		}
		
			ul.callus {
				margin: 0;
				padding: 0;
				float: right;
				
				li{
					list-style:none;
					padding-top: 50px;
					padding-bottom: 15px;
					color:$brand-primary;
					
					i,
					span a {
						color:$brand-primary;
						font-size:16px;
					}
				}
			}
		
			ul.navbar-nav {
				float: right;
				overflow: visible ;
				transition: padding 0.8s ease, margin 0.25s ease;
				min-height: 1px;
				line-height: 1px;
				
				li {
				
					a {
						font-family: Open Sans;
						text-transform: capitalize;
						font-size: 17px;
						line-height: 23.8px;
						font-weight: 400;
						padding-top: 50px;
						padding-bottom: 15px;
					}
					
					.dropdown-menu {
						background-color: $gray-darkest;
					
						li {
							
							a {
								font-size: 12px;
								line-height: 13px;
								color: #ccc;
								transition: none;
								letter-spacing: 0;
								background-color: $gray-darkest;
								margin: 0;
								padding: 14px;
								border-bottom: 1px solid rgba(255,255,255,0.08);
								
								&:hover {
									color: $brand-primary;
									text-decoration:none;
									background-color: $gray-dark;
								}
							}
						}
					}
				}
			}
	}
}

.page-header-no-title {
    padding-bottom: 0;
    margin: 40px 0 0;
    border-bottom: none;
}

.page-header-lower-title {
    padding-bottom: 9px;
    margin: 0 0 20px;
    border-bottom: 1px solid #f8f8f8;
}