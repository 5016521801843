/********
Sticky menu
********/
.myfixed { 
	margin:0 auto!important; 
	float:none!important; 
	border:0px!important; 
	background:none!important; 
	max-width:100%!important; 
	height:90px !important;

	.navbar-brand {
		padding: 5px 0!important;
	}
	
	.navbar-brand>img {
		height: 45px !important;
	}
	nav ul.navbar-nav li a {
		padding-top:20px !important;
	}
}
/********
Orange Image Row
********/
.image-row {
	background:$brand-primary;

	h2 {
    color: $white;
	}
}

/********
Breadcrumbs
********/
#breadcrumbs {
	font-size:.9em;
}

/********
Font
********/
p {
	font-family: Open Sans;
	font-size:1.2em;
	line-height:2em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
		font-family: Open Sans;
    text-transform: uppercase;
}
h1 {
	font-size:28px;
}
h2 {
	font-size:22px;
}
/********
Links
********/
a {
	color:$link-color;
	transition: color 0.2s linear;
	text-decoration:none;

	&:hover {
		color: $brand-primary;
		text-decoration:none;
	}
	
	&:visited {
		color: $white;
	}
	
	&:active {
		color: $brand-primary;
	}
}

.button {
    background: $brand-primary !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
    color: #fff !important;
    font-weight: 700;
    text-align: center;
    border: none;
    width: 100% !important;
    padding: 12px 30px !important;
    text-transform: uppercase;
    margin: 0px auto !important;
    display: block;
}
#gravityflow-status-list .button  {
    background: #f47421!important;
    font-size: 18px!important;
    text-transform: uppercase!important;
    color: #fff!important;
    font-weight: 700;
    text-align: center;
    border: none;
    /* width: 100%!important; */
    padding: 5px 10px!important;
    text-transform: uppercase;
    margin: 10px 0!important;
    display: block;
    /* float: left; */
    width: 135px !important;
}

.main {
	a {
		color:$brand-primary;
		transition: color 0.2s linear;
		text-decoration:none;
	
		&:hover {
			color: $gray-darker;
			text-decoration:none;
		}
		
		&:visited {
			color: $brand-primary;
		}
		
		&:active {
			color: $brand-primary;
		}
	}
}

/********
Visual Composer
********/
.vc_box_border_orange {
    background-color: $brand-primary !important;
}
.vc_box_border_grey {
    background-color:$gray-dark !important;
}
.vc_cta3-actions {
	width:32%;
	text-align:right;
}

/********
Gravity Flow
********/
td.entry-view-field-value {
	padding: 7px 7px 7px 7px !important;
}
td.entry-view-field-name {
	background-color: #eee !important;
}
.gravityflow-step-approval thead tr th.details {
	color: $brand-primary !important;
}
.gravityflow_workflow_wrap .postbox-container .button {
	height:auto !important;
	color: $white !important;
}

/********
Incident Page
********/
#gform_wrapper_7 {
	label,
	label.gfield_label {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.gform_wrapper ul li.gfield {
    margin-bottom: 20px;
	}
	.gform_button { 
		width:100% !important;
	}
	.ginput_container input.medium, 
	.ginput_container select.medium, 
	.ginput_container textarea.medium {
    width: 100% !important
	}
}