.home-service h2 {
	font-size:22px;
	text-transform:capitalize;
}
.region-list-items h3 {
    font-size: 18px !important;
    padding-top: 11px !important;
}


/******
Service and Region Pages
******/
h2.regions-header {
	text-align: center;
	margin-top: 35px;
	padding: 25px 0;
	border-top: 1px solid $gray-light;
	border-bottom: 1px solid $gray-light;
}
h4.page-header-lower-title {
	font-size:20px;
}

/******
Sidebar
******/
.page-sidebar .widget_text,
.region-services-list {
	background: #efefef;
	padding-bottom:2em;
	margin-bottom:2em;
	
	 h3,
	 h5 {
    background: $brand-primary;
    line-height: 30px;
    padding: 25px 15px;
    text-align: center;
    color: #fff;
		margin-top:0;
	}
	h3 {
		font-size: 25px;
	}
	h5 {
		font-size: 20px;
	}

	.anchor {
    background: $brand-primary !important;
    font-size: 14px !important;
    text-transform: lowercase !important;
    color: #fff !important;
    font-weight: 700;
    text-align: center !important;
    border-radius: 2px;
    width: 91% !important;
    padding: 12px 30px !important;
    margin: 0% auto 4% !important;
    display: block;
	}
	h4 {
		text-align:center;
	}
}
.region-services-list {
	.service-list {
		ul {
			padding-left:0;
			li {
				font-size: 1.5em;
				line-height: 1.7em;
				list-style-image: url(../images/guardex-ex.png);
				border-bottom: 1px solid #e7e7e7;
				margin-bottom: 10px;
				width: 100%;
				list-style-position: inside;
				padding-left: 15px;
				
				&:hover {
					background-color:$gray-lighter;
				}
				a {
					display:block;
				}
			}
		}
	}
}